<template>
  <div class="login-page full-height columns gap2">
    <div class="span-5 p-4 text-center flex fd-c jc-c">
      <h1 class="mb-1 text-xg">Acesso do Portal</h1>
      <p class="mb-3">Digite o seu email e senha para acessar</p>

      <form class="flex fd-c fgap2" @submit.prevent="login">
        <Input
          v-model="userData.email"
          :required="true"
          className="input-login"
          label="Email"
          name="email"
          placeholder="Seu email"
          type="email"
        />

        <Input
          v-model="userData.senha"
          :required="true"
          className="input-login"
          label="Senha"
          name="senha"
          placeholder="Sua senha"
          type="password"
        />

        <div class="pv-1 text-right">
          <router-link :to="{name: 'ForgotPassword'}">
            Esqueceu sua senha?

          </router-link>
        </div>

        <button class="btn solid primary full-width extra-big" type="submit">ACESSAR</button>
        <div v-if="loading">carregando</div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';
import Input from '@/components/DataInput/Input.vue';

export default {
  name: 'Login',
  components: {
    Input,
  },
  computed: {
    ...mapState(['loading']),
  },
  data() {
    return {
      userData: {
        email: '',
        senha: '',
      },
    };
  },
  methods: {
    ...mapActions(['toggleLoading']),
    ...mapActions('auth', ['updateUser']),
    async login() {
      this.toggleLoading(true);
      try {
        const params = new URLSearchParams();
        params.append('grant_type', 'password');
        params.append('username', this.userData.email);
        params.append('password', this.userData.senha);

        await api.post('/oauth/token', params, {
          auth: {
            username: process.env.VUE_APP_API_USER,
            password: process.env.VUE_APP_API_PWD,
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        const userResponse = await api.get('/credential');
        this.updateUser(userResponse.data.data);

        this.$router.push('/');
      } catch (err) {
        this.$toast.error('Não foi possível executar esta ação no momento!');
      }
      this.toggleLoading(false);
    },
  },
};
</script>

<style scoped>
.login-page {
  background: url("../../assets/img/bg-login.jpg") right center / auto 100% no-repeat, var(--white-color);
}

form {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 1300px) {
  .login-page.columns {
    grid-template-columns: 1fr;
    background: var(--white-color);
  }
}
</style>
