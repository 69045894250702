<template>
  <div>
    <div class="flex ai-c fgap2 jc-sb mt-2 ">
      <div class="flex fgap2 fw-w">
        <div class="flex ai-c fgap1">
          <Toggle v-model="process.importLicense" :disabled="disableClick" name="importLicense" :labels="{checked: 'POSSUI LICENÇA DE IMPORTAÇÃO', unchecked: 'NÃO POSSUI LICENÇA DE IMPORTAÇÃO',}" @input="() => inputUpdate('importLicense')" classLabel="text-medium text-md"/>
        </div>
        <div class="flex ai-c fgap1" v-if="process.urgent" style="border-radius: var(--medium-border-radius); padding: 3px 6px; border: 1px solid var(--danger-color);">
          <WarningIcon class="text-danger" size="22" />
          <span class="text-medium text-md">URGENTE</span>
        </div>
        <div class="flex ai-c fgap1" v-if="process.imo" style="border-radius: var(--medium-border-radius); padding: 3px 6px; border: 1px solid var(--danger-color);">
          <WarningIcon class="text-danger" size="22"/>
          <span class="text-medium text-md">IMO</span>
        </div>
        <div class="flex ai-c fgap1" v-if="process.duimp" style="border-radius: var(--medium-border-radius); padding: 3px 6px; border: 1px solid var(--danger-color);">
          <WarningIcon class="text-danger" size="22"/>
          <span class="text-medium text-md">POSSUI DUIMP</span>
        </div>
      </div>
    </div>

    <!-- <div class="modal-summary-alerts columns gap2 mt-2" v-if="missingDocs">
      <div class="span-6 alert flex fgap2 ai-c primary-bg p-1">
        <div class="alert-icon">
          <NotAllowedIcon class="text-white" />
        </div>
        <div class="alert-content flex1 text-white">
          <p>Este processo ainda não tem <strong>Autorização de Registro</strong>. Clique no botão ao lado para pedir autorização!</p>
        </div>
        <div class="alert-action">
          <a href="#" class="btn solid white text-primary">Solicitar</a>
        </div>
      </div>

      <div class="span-12 alert flex fgap2 ai-c danger-bg p-1">
        <div class="alert-icon">
          <TasksIcon class="text-white" />
        </div>
        <div class="alert-content flex1 text-white">
          <p>Este processo não tem todos os <strong>Documentos Originais</strong> que são necessários para despacho.</p>
        </div>
        <div class="alert-action">
          <a href="#" class="btn solid white text-primary">Solicitar</a>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  FlagIcon,
  FileIcon,
  BuildingIcon,
  InvoiceIcon,
  BoxesIcon,
  TasksIcon,
  WarningIcon,
} from '@/components/Icons';
import Toggle from '@/components/DataInput/Toggle.vue';

export default {
  name: 'ModalProcessSummary',
  components: {
    FlagIcon,
    FileIcon,
    Toggle,
    BuildingIcon,
    InvoiceIcon,
    BoxesIcon,
    TasksIcon,
    WarningIcon,
  },
  props: {
    process: {
      type: Object,
      default: () => {},
    },
    disableClick: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      missingDocs: false,
    };
  },
  methods: {
    activeMissingDocs() {
      this.missingDocs = true;
    },
  },
  mounted() {
    window.addEventListener('missingDoc', this.activeMissingDocs);
  },
  beforeUnmount() {
    window.removeEventListener('missingDoc', this.activeMissingDocs);
  },
};
</script>

<style scoped>
</style>
